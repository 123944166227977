import { createContext, createEffect, Show, Suspense, useContext } from 'solid-js';
import { useAnalytics } from '@troon/analytics';
import { setUser } from '@sentry/solidstart';
import { getRequestEvent, isServer } from 'solid-js/web';
import { query, createAsync, redirect } from '@solidjs/router';
import { loggedInUserQuery } from '../queries/user';
import { getApiClient } from '../graphql';
import { ttlCache } from '../graphql/cache';
import type { Accessor, ParentProps } from 'solid-js';
import type { LoggedInUserQuery } from '../graphql/__generated__/graphql';

type Data = LoggedInUserQuery;
type CTX = Accessor<Data | null>;

const User = createContext<CTX>();

export function UserContext(props: ParentProps) {
	// Pre-cache the logged in user from the window to the ttlCache
	// While solid router's `query()` does _some_ caching, the first mutation will automatically invalidate
	// its internal cache. Our custom ttlCache can prevent this from doing unnecessary extra lookups.
	if (!isServer && window.__USER__) {
		ttlCache?.set('loggedInUser', window.__USER__);
	}
	const user = createAsync(() => getLoggedInUser(), { deferStream: true });
	const analytics = useAnalytics();

	createEffect(() => {
		const data = user();
		if (data) {
			analytics?.identify(
				data.me.id,
				{
					email: data.me.email,
					fullName: `${data.me.firstName} ${data.me.lastName}`,
					accessSubscription: data.me.troonAccessProductType,
					rewardsId: data.me.troonRewardsId,
				},
				{
					card: data.me.card?.name,
				},
			);
			if (!isServer && window.__USER__?.me.id && window.__USER__?.me.id !== data.me.id) {
				analytics?.alias(window.__USER__.me.id);
			}
			setUser({ id: data.me.id, email: data.me.email, fullName: `${data.me.firstName} ${data.me.lastName}` });
		}
	});

	return (
		<Suspense>
			<Show when={user() !== undefined}>
				<User.Provider value={user as Accessor<Data | null>}>{props.children}</User.Provider>
			</Show>
		</Suspense>
	);
}

export function useUser(): Accessor<LoggedInUserQuery | null> {
	const ctx = useContext(User);
	if (!ctx) {
		throw new Error('User context not initialized');
	}
	return ctx;
}

// Manually reimplement lazy/minimal cachedGet
const getLoggedInUser = query(async () => {
	const cachedData: LoggedInUserQuery | undefined = ttlCache?.get<LoggedInUserQuery>('loggedInUser');
	if (ttlCache?.has('loggedInUser')) {
		return cachedData;
	}

	if (isServer) {
		const event = getRequestEvent()!;
		if (event.locals.user) {
			ttlCache?.set('loggedInUser', event.locals.user);
			return event.locals.user;
		} else {
			return null;
		}
	}

	try {
		const res = await getApiClient().query(loggedInUserQuery, {});
		ttlCache?.set('loggedInUser', res.data);
		return res.data || null;
	} catch {
		return null;
	}
}, 'loggedInUser');

export const requireLoggedIn = query(async () => {
	const user = await getLoggedInUser();
	if (user === null) {
		const url = isServer ? new URL(getRequestEvent()!.request.url) : new URL(window.location.toString());
		if (!url.searchParams.get('redirect')) {
			url.searchParams.set('redirect', url.pathname);
		}
		return redirect(`/auth?${url.searchParams.toString()}`, { status: 401 });
	}
	return user;
}, 'require-logged-in-user');
